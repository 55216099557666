<script>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	import { version } from '../../package.json';
</script>

<main>
	<hr />
	<div id="footer">
		<span style="float:left">Built with SvelteJS.</span>
		<span style="float:right">Nutra Web (v{version})</span>
	</div>
</main>
