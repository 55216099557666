<script>
	import { STORE_URL } from './constants.ts';
</script>

<main>
	<nav class="container-fluid">
		<ul>
			<li>
				<a href="/" aria-label="Back home">
					<!--suppress CheckImageSize -->
					<img src="/favicon.png" width="35" alt="Home" />
				</a>
			</li>
			<li>
				<a href="/" style="text-decoration: none" aria-label="Back home"> NutraTech </a>
			</li>
		</ul>
		<ul>
			<li><a href="/calculators/" class="secondary">Calculators</a></li>
			<li><a rel="external" href="/blog/" class="secondary">Blog</a></li>
			<li><a rel="external" href={STORE_URL} class="secondary">Store</a></li>
			<li>
				<a
					href="https://github.com/nutratech/web"
					class="contrast"
					aria-label="Pico GitHub repository"
				>
					<img src="/github-mark.svg" alt="Source" aria-hidden="true" width="25" />
				</a>
			</li>
		</ul>
	</nav>
</main>
